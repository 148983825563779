import React from "react";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router";

import { useRouteError } from "react-router";
const ErrorPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.href.indexOf("spotify-tool") > -1) {
      navigate("/spotify-tool");
    }
  }, []);

  const error = useRouteError();
  console.log(error);
  return (
    <>
      <h1>Uh oh, this is a bit empty.</h1>
      <p style={{ fontSize: "16pt" }}>Looks like something got misplaced.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </>
  );
};

export default ErrorPage;
